<template>
  <b-card>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="username"
              placeholder="Username"
              name="username"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Twitch-Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="twitch"
              name="name"
              placeholder="Unknown"
              :readonly="!changeTwitchnameAllowed"
            />
          </b-form-group>
          <b-button
            v-if="changeTwitchnameAllowed"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="changeName"
          >
            Save Name
          </b-button>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="mail"
              name="email"
              placeholder="Email"
              readonly
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-alert
            v-if="!changeTwitchnameAllowed"
            show
            variant="warning"
            class="mt-2"
          >
            <h4 class="alert-heading">
              If you want to change your Twitch-Name please contact us via Discord
            </h4>
          </b-alert>
        </b-col>

        <!-- alert -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          class="mt-75"-->
        <!--        >-->
        <!--          <b-alert-->
        <!--            show-->
        <!--            variant="warning"-->
        <!--            class="mb-50"-->
        <!--          >-->
        <!--            <h4 class="alert-heading">-->
        <!--              Your email is not confirmed. Please check your inbox.-->
        <!--            </h4>-->
        <!--            <div class="alert-body">-->
        <!--              <b-link class="alert-link">-->
        <!--                Resend confirmation-->
        <!--              </b-link>-->
        <!--            </div>-->
        <!--          </b-alert>-->
        <!--        </b-col>-->
        <!--/ alert -->

        <!--        <b-col cols="12">-->
        <!--          <b-button-->
        <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--            variant="primary"-->
        <!--            class="mt-2 mr-1"-->
        <!--          >-->
        <!--            Save changes-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
        <!--            variant="outline-secondary"-->
        <!--            type="reset"-->
        <!--            class="mt-2"-->
        <!--            @click.prevent="resetForm"-->
        <!--          >-->
        <!--            Reset-->
        <!--          </b-button>-->
        <!--        </b-col>-->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    // BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      username: null,
      mail: null,
      twitch: null,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      changeTwitchnameAllowed: false,
    }
  },
  mounted() {
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        this.mail = snap.data().email
        this.twitch = snap.data().twitchname
        this.username = snap.data().username
        this.changeTwitchnameAllowed = snap.data().License?.changeTwitchnameAllowed
      })
  },
  methods: {
    changeName() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .set({
          twitchname: this.twitch.toLowerCase(),
        }, { merge: true }).then(() => {
          this.$swal({
            title: 'Succeed!',
            text: 'Changed your Name!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
