<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!--        &lt;!&ndash; old password &ndash;&gt;-->
        <!--        <b-col md="6">-->
        <!--          <b-form-group-->
        <!--            label="Old Password"-->
        <!--            label-for="account-old-password"-->
        <!--          >-->
        <!--            <b-input-group class="input-group-merge">-->
        <!--              <b-form-input-->
        <!--                id="account-old-password"-->
        <!--                v-model="passwordValueOld"-->
        <!--                name="old-password"-->
        <!--                :type="passwordFieldTypeOld"-->
        <!--                placeholder="Old Password"-->
        <!--              />-->
        <!--              <b-input-group-append is-text>-->
        <!--                <feather-icon-->
        <!--                  :icon="passwordToggleIconOld"-->
        <!--                  class="cursor-pointer"-->
        <!--                  @click="togglePasswordOld"-->
        <!--                />-->
        <!--              </b-input-group-append>-->
        <!--            </b-input-group>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ old password &ndash;&gt;-->
        <!--      </b-row>-->
        <!--      <b-row>-->
        <!--        &lt;!&ndash; new password &ndash;&gt;-->
        <!--        <b-col md="6">-->
        <!--          <b-form-group-->
        <!--            label-for="account-new-password"-->
        <!--            label="New Password"-->
        <!--          >-->
        <!--            <b-input-group class="input-group-merge">-->
        <!--              <b-form-input-->
        <!--                id="account-new-password"-->
        <!--                v-model="newPasswordValue"-->
        <!--                :type="passwordFieldTypeNew"-->
        <!--                name="new-password"-->
        <!--                placeholder="New Password"-->
        <!--              />-->
        <!--              <b-input-group-append is-text>-->
        <!--                <feather-icon-->
        <!--                  :icon="passwordToggleIconNew"-->
        <!--                  class="cursor-pointer"-->
        <!--                  @click="togglePasswordNew"-->
        <!--                />-->
        <!--              </b-input-group-append>-->
        <!--            </b-input-group>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ new password &ndash;&gt;-->

        <!--        &lt;!&ndash; retype password &ndash;&gt;-->
        <!--        <b-col md="6">-->
        <!--          <b-form-group-->
        <!--            label-for="account-retype-new-password"-->
        <!--            label="Retype New Password"-->
        <!--          >-->
        <!--            <b-input-group class="input-group-merge">-->
        <!--              <b-form-input-->
        <!--                id="account-retype-new-password"-->
        <!--                v-model="RetypePassword"-->
        <!--                :type="passwordFieldTypeRetype"-->
        <!--                name="retype-password"-->
        <!--                placeholder="New Password"-->
        <!--              />-->
        <!--              <b-input-group-append is-text>-->
        <!--                <feather-icon-->
        <!--                  :icon="passwordToggleIconRetype"-->
        <!--                  class="cursor-pointer"-->
        <!--                  @click="togglePasswordRetype"-->
        <!--                />-->
        <!--              </b-input-group-append>-->
        <!--            </b-input-group>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ retype password &ndash;&gt;-->

        <!-- buttons -->
        <b-col cols="12">
          <div class="">
            <h4>
              Send E-Mail to reset password
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="reset"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import firebase from 'firebase'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    reset() {
      const userData = getUserData()
      // console.log(userData.id)
      const email = firebase.auth().currentUser.email
      const sx = this.$firebase.firestore()
      firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          this.$swal({
            title: 'Succeed!',
            text: 'Email was sent to your mail.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          // ..
        })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
